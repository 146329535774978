import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../state/UserContext";
import { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { auth, firebaseFunctions } from "../users/firebase";
import { MoonLoader } from "react-spinners";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const AccountPage = () => {
  const navigate = useNavigate();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (user === undefined) {
      // Assume user context is still loading
      setIsLoading(true);
    } else if (!user) {
      // If no user is logged in, navigate to home page
      navigate("/");
    } else {
      // User is available, stop loading
      setIsLoading(false);
    }
  }, [navigate, user]);

  const joinCommunity = async () => {
    try {
      setIsLoading(true);
      const result = await firebaseFunctions.createPaymentLink();
      window.location.href = result.data.paymentLink;

      setIsLoading(false);
      setError("");
    } catch (error) {
      setError("Failed to join community. Please try again later");
    }
  };

  const leaveCommunity = async () => {
    if (
      window.confirm(
        "Are you sure you want to cancel your subscription? You will have access until the next billing cycle"
      )
    ) {
      try {
        setIsLoading(true);
        await firebaseFunctions.leaveCommunity();
        setIsLoading(false);
        location.reload();
      } catch (error) {
        alert("Failed to cancel subscription. Please try again later");
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      try {
        setIsLoading(true);
        await firebaseFunctions.deleteUser();
        setIsLoading(false);
      } catch (error) {
        alert("Failed to delete your account. Please try again later");
      }
      auth.signOut();
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <MoonLoader color="white" />
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-900 text-gray-200">
      <Link to="/" className="absolute left-10 top-10 text-gray-400 hover:text-gray-200">
        <IoMdArrowRoundBack className="w-10 h-10" />
      </Link>
      <div className="flex flex-col gap-4 text-center p-6 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-[#03BF9C]">Welcome, {user.firstName} {user.lastName}</h2>
        {!user.moneybirdSubscriptionId && (
          <button onClick={joinCommunity} className="bg-[#03BF9C] text-white py-2 px-4 rounded-lg hover:bg-[#04D88A] transition transform hover:scale-105">
            {user.isActiveCommunityMember ? "Resume subscription" : "Start subscription"}
          </button>
        )}
        {user.isActiveCommunityMember && user.moneybirdSubscriptionId && (
          <button onClick={leaveCommunity} className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition transform hover:scale-105">
            Cancel subscription
          </button>
        )}
        {error.length > 0 && <p className="text-red-500">{error}</p>}
        <button
          onClick={handleDelete}
          className="bg-gray-700 text-red-500 py-2 px-4 rounded-lg hover:bg-gray-600"
        >
          Delete my account
        </button>
      </div>
    </div>
  );
};
