// ModalLogin.tsx
import React, { useState, useCallback, useMemo } from "react";
import { MoonLoader } from "react-spinners";
import { HomePageModal } from "./HomePage";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import logoKleurOpTransparant from "../assets/BNN_RGB_kleuroptransparant.svg"

interface ModalLoginProps {
  onClose: (switchToOtherModal?: HomePageModal) => void;
}

export const ModalPasswordReset: React.FC<ModalLoginProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [error] = useState("");
  const [isPending] = useState(false);
  const auth = getAuth();

  const resetPassword = useCallback(async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Wachtwoordherstel e-mail verstuurd.");
    } catch (error) {
      console.error("Fout bij het versturen van de e-mail:", error);
    }
  }, [email])

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-90 flex justify-center items-center">
      <div className="bg-darkblue rounded-lg shadow-lg w-96 p-6 relative">
        <h2 className="text-2xl font-bold text-[#03BF9C] mb-4 text-center">Wachtwoord vergeten</h2>
        <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
          <div>
            <p>Oh dommie! Nou vooruit, vul je e-mailadres in</p>
            <input
              type="email"
              className="w-full px-3 mt-4 py-2 border border-gray-600 rounded-lg bg-gray-700 text-gray-200"
              placeholder="Voer je e-mailadres in"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <div className="mt-6">
            {!isPending && (
              <button
                type="button"
                className="bg-[#03BF9C] hover:bg-[#04D88A] text-white font-bold py-2 px-4 rounded w-full"
                onClick={resetPassword}
              >
                Stuur reset email
              </button>
            )}

            {isPending && (
              <div className="flex justify-center w-full">
                <MoonLoader size={24} color="white" />
              </div>
            )}
          </div>

          <hr className="mt-4 border-gray-600" />

          <button
            onClick={() => onClose()}
            className="absolute -top-4 right-3 text-gray-400 hover:text-gray-200 text-3xl"
          >
            x
          </button>
          <div className="text-center mt-4 text-darkgray">
            <button onClick={() => onClose("login")}>Wacht! Ik weet hem weer.</button>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={logoKleurOpTransparant}
              alt="BIMnerd Network Logo"
              style={{ height: 50 }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
