// ModalLogin.tsx
import React, { useState, useCallback, useMemo } from "react";
import { MoonLoader } from "react-spinners";
import MailChecker from "mailchecker";
import { useFirebaseActions } from "../users/firebase-actions";
import { HomePageModal } from "./HomePage";
import logoKleurOpTransparant from "../assets/BNN_RGB_kleuroptransparant.svg"

interface ModalRegisterProps {
  onClose: (switchToOtherModal?: HomePageModal) => void;
}

export const ModalRegister: React.FC<ModalRegisterProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [error, setError] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { registerUser } = useFirebaseActions();

  // Validate email
  const formError = useMemo(() => {
    if (!MailChecker.isValid(email)) {
      return "Voer een geldig e-mailadres in";
    }
    if (
      phoneNumber.toString().length < 8 ||
      phoneNumber.toString().indexOf(".") !== -1
    ) {
      return "Een echt telefoonnummer ajb";
    }
    if (password.length < 8) {
      return "Wachtwoord van minimaal tekens";
    }
    if (firstName.length < 2) {
      return "Voer je voornaam in";
    }
    if (lastName.length < 2) {
      return "Voer je achternaam in";
    }
    if (password !== passwordRepeat) {
      return "Wachtwoorden komen niet overeen.";
    }
    return undefined;
  }, [email, firstName.length, lastName.length, password, passwordRepeat, phoneNumber]);

  // Handle login logic
  const handleRegister = useCallback(
    async (e: any) => {
      e.preventDefault();

      if (formError) {
        setError(formError);
        return;
      }

      setIsPending(true);
      const result = await registerUser(email, password, phoneNumber, firstName, lastName);
      setIsPending(false);

      if (result?.errorMessage) {
        setError(result.errorMessage);
      } else {
        onClose(); // Close the modal on success
      }
    },
    [email, formError, firstName, lastName, password, phoneNumber, registerUser]
  );

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-90 flex justify-center items-center">
      <div className="bg-darkblue rounded-lg shadow-xl w-full max-w-md p-8 relative">
        <h2 className="text-2xl font-bold text-[#03BF9C] mb-6 text-center">Registreren</h2>
        <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
          <div>
            <label htmlFor="email" className="block text-gray-300 text-sm mb-2">E-mailadres</label>
            <input
              id="email"
              type="email"
              autoComplete="email"
              className="w-full px-4 py-2 bg-gray-800 text-gray-100 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#03BF9C]"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex gap-4">
            <div>
              <label htmlFor="firstName" className="block text-gray-300 text-sm mb-2">Voornaam</label>
              <input
                id="firstName"
                autoComplete="given-name"
                type="text"
                className="w-full px-4 py-2 bg-gray-800 text-gray-100 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#03BF9C]"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-gray-300 text-sm mb-2">Achternaam</label>
              <input
                id="lastName"
                autoComplete="family-name"
                type="text"
                className="w-full px-4 py-2 bg-gray-800 text-gray-100 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#03BF9C]"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-300 text-sm mb-2">Wachtwoord</label>
            <input
              id="password"
              autoComplete="new-password"
              type="password"
              className="w-full px-4 py-2 bg-gray-800 text-gray-100 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#03BF9C]"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="passwordAgain" className="block text-gray-300 text-sm mb-2">Wachtwoord herhalen</label>
            <input
              id="passwordAgain"
              autoComplete="new-password"
              type="password"
              className="w-full px-4 py-2 bg-gray-800 text-gray-100 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#03BF9C]"
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="phoneNumber" className="block text-gray-300 text-sm mb-2">Telefoonnummer (voor WhatsApp)</label>
            <input
              id="phoneNumber"
              autoComplete="tel"
              type="tel"
              className="w-full px-4 py-2 bg-gray-800 text-gray-100 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#03BF9C]"
              onChange={(e) => setPhoneNumber(parseInt(e.target.value))}
            />
          </div>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          <div className="mt-6">
            {!isPending && (
              <button
                type="button"
                className="bg-[#03BF9C] hover:bg-[#04D88A] text-white font-bold py-2 px-4 rounded w-full"
                onClick={handleRegister}
              >
                Registreren
              </button>
            )}

            {isPending && (
              <div className="flex justify-center w-full">
                <MoonLoader size={24} color="#03BF9C" />
              </div>
            )}
          </div>
          <hr className="border-gray-600 my-4" />
          <button
            type="button"
            className="bg-[#034872] hover:bg-[#01283F] text-white font-bold py-2 px-4 rounded w-full"
            onClick={() => onClose("login")}
          >
            Inloggen met bestaand account
          </button>
        </form>
        <button
          onClick={() => onClose()}
          className="absolute top-4 right-6 text-gray-400 hover:text-gray-200 text-3xl"
        >
          ×
        </button>
        <div className="flex justify-center items-center mt-4">
          <img
            src={logoKleurOpTransparant}
            alt="BIMnerd Network Logo"
            style={{ height: 50 }}
          />
        </div>
      </div>
    </div>
  );
};
