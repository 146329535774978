// Header.tsx
import React from "react";

export const Footer: React.FC = ({ }) => {
  return (
    <footer className="bg-darkblue text-white py-6 mt-8 border-t-4 border-petrolblue">
      <div className="container mx-auto px-4 mt-6">
        <p className="text-center">© 2025 BIMnerd Network. Alle rechten voorbehouden.</p>
      </div>
    </footer>
  );
};
