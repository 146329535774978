// Header.tsx
import React, { useState } from "react";
import { useUser } from "../state/UserContext";
import { useNavigate } from "react-router-dom";
import logoKleurOpTransparant from "../assets/BNN_RGB_kleuroptransparant.svg";
import { auth, firebaseFunctions } from "../users/firebase";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"

interface HeaderProps {
  onLoginClick: () => void;
  onLogoutClick: () => void;
  onRegisterClick: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  onLoginClick,
  onLogoutClick,
  onRegisterClick,
}) => {
  const user = useUser();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleJoinCommunity = async () => {
    try {
      setIsLoading(true);
      const result = await firebaseFunctions.createPaymentLink();
      window.location.href = result.data.paymentLink;
      setIsLoading(false);
      setError("");
    } catch (err) {
      setError("Het is niet gelukt om je aan de community toe te voegen. Probeer het later nog eens.");
      setIsLoading(false);
    }
  };

  const handleLeaveCommunity = async () => {
    if (
      window.confirm(
        "Weet je zeker dat je je abonement wilt opzeggen? Je hebt nog toegang tot het einde van deze factuur"
      )
    ) {
      try {
        setIsLoading(true);
        await firebaseFunctions.leaveCommunity();
        setIsLoading(false);
        location.reload();
      } catch (err) {
        alert("Het is niet gelukt het abonement op te zeggen. Probeer het later nog eens. Of neem contact op met een andmin");
        setIsLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Weet je zeker dat je het account wilt opzeggen?")) {
      try {
        setIsLoading(true);
        await firebaseFunctions.deleteUser();
        setIsLoading(false);
      } catch (error) {
        alert("Het is niet gelukt je account op te zeggen. Probeer het later nog eens. Of neem contact op met een andmin");
      }
      auth.signOut();
    }
  };

  const accountMenu = (
    <div className="absolute right-0 mt-2 py-2 w-64 bg-green rounded-md shadow-xl z-20">
      {!user?.moneybirdSubscriptionId && (
        <button
          onClick={handleJoinCommunity}
          className="block px-4 py-2 hover:bg-lightgray hover:text-darkgray w-full text-left"
        >
          {user?.isActiveCommunityMember
            ? "Abonnement hervatten"
            : "Word lid van de community"}
        </button>
      )}
      {user?.isActiveCommunityMember && user.moneybirdSubscriptionId && (
        <button
          onClick={handleLeaveCommunity}
          className="block px-4 py-2 hover:bg-lightgray hover:text-blu w-full text-left"
        >
          Abonnement opzeggen
        </button>
      )}
      <button
        onClick={handleDelete}
        className="block px-4 py-2 hover:bg-lightgray hover:text-blue w-full text-left"
      >
        Delete Account
      </button>
      <hr className="border-gray-300 my-2" />
      <button
        onClick={onLogoutClick}
        className="block px-4 py-2 hover:bg-lightgray w-full text-left text-blue"
      >
        Uitloggen
      </button>
      {error && <p className="text-sm text-red-500 px-4">{error}</p>}
    </div>
  );

  return (
    <header className="bg-darkblue border-b-4 border-petrolblue text-white py-6 relative">
      <div className="container sm:flex-row flex-col mx-auto px-4 flex justify-between items-center">
        <div className="order-2 sm:order-1 text-center sm:text-left">
          <p className="text-lg">Dé plek voor al je BIMvragen</p>
        </div>
        <div className="text-2xl font-bold order-1 sm:order-2 text-2xl font-bold mb-4 sm:mb-0">
          <img
            src={logoKleurOpTransparant}
            alt="BIMnerd Network Logo"
            style={{ height: 100 }}
          />
        </div>
        <div className="relative order-3 relative text-center sm:text-right">
          {user ? (
            <>
              <button
                onClick={toggleMenu}
                className="bg-green-500 flex hover:bg-applegreen text-white text-lg font-bold py-2 px-4 rounded items-center justify-center"
              >
                Account {isMenuOpen ? <FaChevronUp className="ml-1" /> : <FaChevronDown className="ml-1" />}
              </button>
              {isMenuOpen && accountMenu}
            </>
          ) : (
            <>
              <button
                onClick={onLoginClick}
                className="bg-green-500 hover:bg-applegreen text-white text-lg font-bold py-2 px-4 rounded"
              >
                Inloggen
              </button>
              <button
                onClick={onRegisterClick}
                className="ml-2 bg-blue-500 hover:bg-petrolblue text-applegreen text-lg font-bold py-2 px-4 rounded"
              >
                Registreren
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};
