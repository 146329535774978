import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import {
  UserObject,
  UserRegisterRequest,
} from "../../../functions/shared/requests";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  useEmulator: process.env.REACT_APP_USE_EMULATOR === "true",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

const functions = getFunctions(app, "europe-west1");

if (firebaseConfig.useEmulator) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const firebaseFunctions = {
  getCurrentUserObject: httpsCallable<{}, UserObject>(functions, "user"),
  registerNewUser: httpsCallable<UserRegisterRequest>(functions, "register"),
  createPaymentLink: httpsCallable<{}, { paymentLink: string }>(
    functions,
    "createPaymentLink"
  ),
  leaveCommunity: httpsCallable<{}, {}>(functions, "leaveCommunity"),
  deleteUser: httpsCallable<{}>(functions, "deleteUser"),
  getMemberCount: httpsCallable<{}>(functions, "getMemberCount"),
};
