import React, { useState } from "react";
import { Header } from "./Header";
import { Main } from "./Main";
import { Footer } from "./Footer";
import { ModalLogin } from "./ModalLogin";
import { ModalRegister } from "./ModalRegister";
import { ModalPasswordReset } from "./ModalPasswordReset";

import { auth } from "../users/firebase";

export type HomePageModal = "login" | "register" | "passwordreset";

export const HomePage: React.FC = () => {
  const [loginModalOpen, setLoginModalOpen] = useState<boolean>(false);
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState<boolean>(false);


  const handleLoginOpen = () => {
    setLoginModalOpen(true);
  };

  const handleLoginClose = (switchToOtherModal?: HomePageModal) => {
    setLoginModalOpen(false);
    if (switchToOtherModal === "register") {
      setRegisterModalOpen(true)
    }
    if (switchToOtherModal === "passwordreset") {
      setPasswordResetModalOpen(true);
    }
  };

  const handleRegisterOpen = () => {
    setRegisterModalOpen(true);
  };

  const handleRegisterClose = (switchToOtherModal?: HomePageModal) => {
    setRegisterModalOpen(false);
    if (switchToOtherModal === "login") {
      setLoginModalOpen(true);
    }
  };

  const handlePasswordResetOpen = () => {
    setPasswordResetModalOpen(true);
  };

  const passwordResetClose = (switchToOtherModal?: HomePageModal) => {
    setPasswordResetModalOpen(false);
    if (switchToOtherModal === "login") {
      setLoginModalOpen(true);
    }
  };


  const handleLogoutClick = async () => {
    await auth.signOut();
    alert("Je bent uitgelogd");
  };

  return (
    <div className="bg-darkblue text-lightgray selection:bg-applegreen selection:text-darkblue">
      <Header
        onLoginClick={handleLoginOpen}
        onLogoutClick={handleLogoutClick}
        onRegisterClick={handleRegisterOpen}
      />
      <Main
        onRegisterClick={handleRegisterOpen}
      />
      <Footer />
      {loginModalOpen && <ModalLogin onClose={handleLoginClose} />}
      {registerModalOpen && <ModalRegister onClose={handleRegisterClose} />}
      {passwordResetModalOpen && <ModalPasswordReset onClose={passwordResetClose} />}
    </div>
  );
};
