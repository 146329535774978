// ModalLogin.tsx
import React, { useState, useCallback, useMemo } from "react";
import { MoonLoader } from "react-spinners";
import MailChecker from "mailchecker";
import { useFirebaseActions } from "../users/firebase-actions";
import { HomePageModal } from "./HomePage";
import logoKleurOpTransparant from "../assets/BNN_RGB_kleuroptransparant.svg"

interface ModalLoginProps {
  onClose: (switchToOtherModal?: HomePageModal) => void;
}

export const ModalLogin: React.FC<ModalLoginProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isPending, setIsPending] = useState(false);

  const { loginUser } = useFirebaseActions();

  // Validate email
  const formError = useMemo(() => {
    if (!MailChecker.isValid(email)) {
      return "Enter a valid email address";
    }
    return undefined;
  }, [email]);

  // Handle login logic
  const handleLogin = useCallback(async () => {
    if (formError) {
      setError(formError);
      return;
    }

    setIsPending(true);
    const result = await loginUser(email, password);
    setIsPending(false);

    if (result?.errorMessage) {
      setError(result.errorMessage);
    } else {
      onClose(); // Close the modal on success
    }
  }, [email, password, formError, loginUser, onClose]);

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-90 flex justify-center items-center">
      <div className="bg-darkblue rounded-lg shadow-lg w-96 p-6 relative">
        <h2 className="text-2xl font-bold text-[#03BF9C] mb-4 text-center">Login</h2>
        <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
          <div>
            <label className="block text-gray-200 text-sm font-bold mb-2">
              E-mailadres
            </label>
            <input
              type="email"
              className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-gray-200"
              placeholder="Voer je e-mailadres in"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-200 text-sm font-bold mb-2">
              Wachtwoord
            </label>
            <input
              type="password"
              className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-gray-200"
              placeholder="Voer je wachtwoord in"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <div className="mt-6">
            {!isPending && (
              <button
                type="button"
                className="bg-[#03BF9C] hover:bg-[#04D88A] text-white font-bold py-2 px-4 rounded w-full"
                onClick={handleLogin}
              >
                Login
              </button>
            )}

            {isPending && (
              <div className="flex justify-center w-full">
                <MoonLoader size={24} color="white" />
              </div>
            )}
          </div>

          <hr className="my-4 border-gray-600" />

          <button
            type="button"
            className="bg-[#034872] hover:bg-[#01283F] text-white font-bold rounded py-2 px-4 w-full"
            onClick={() => onClose("register")}
          >
            Registreer nieuw account
          </button>

          <button
            onClick={() => onClose()}
            className="absolute -top-4 right-3 text-gray-400 hover:text-gray-200 text-3xl"
          >
            ×
          </button>
          <div className="text-center mt-4 text-darkgray">
            <button onClick={() => onClose("passwordreset")}>ai, wachtwoord vergeten</button>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={logoKleurOpTransparant}
              alt="BIMnerd Network Logo"
              style={{ height: 50 }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
